import React, { FormEvent, PureComponent } from 'react';

import {
  Card,
  CardContent,
  Box,
  Switch,
  IconButton,
  Snackbar,
  Alert,
  AlertTitle,
  Typography,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import red from '@mui/material/colors/red';
import { Delete } from '@mui/icons-material';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { Link } from 'react-router-dom';

import OverflowTooltip from '../shared/OverflowTooltip';

import { loadProfile } from '../../services/Auth';
import * as API from '../../services/API';
import {
  GroupRestrictionLevel,
  ModelMembership,
  ModelMembershipRole,
  UserRole,
} from '../../types/models';

import { ProfileProps, withProfile } from '../shared/AuthController';
import APICacheContext from '../shared/APICacheContext';
import UtilClasses from '../../assets/stylesheets/UtilClasses.module.scss';
import APICache from '../../services/APICache';
import PopupDialog from '../shared/PopupDialog';

interface Props extends ProfileProps {
  membership: ModelMembership;
  clientId: number;
  modelId: number;
  filter: string;
}

interface State {
  busy: boolean;
  error?: Error;
  deleteModalFlag: boolean;
  isDeleting: boolean;
}

const INITIAL_STATE: State = {
  busy: false,
  error: undefined,
  deleteModalFlag: false,
  isDeleting: false,
};

class TeamMemberCard extends PureComponent<Props, State> {
  public static contextType = APICacheContext;
  public state = INITIAL_STATE;

  public render() {
    const { membership, profile, clientId, modelId, filter } = this.props;
    const { busy, error, isDeleting } = this.state;

    // Non-admin users may not delete their own membership to a model
    const locked =
      profile !== undefined &&
      membership.User.id === profile.User.id &&
      profile.User.Role !== UserRole.Trinity_Admin_User;

    return (
      <Card>
        <CardContent sx={{ p: '0.75rem !important' }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid xs={5}>
              <Typography flex="auto" mr={2}>
                <Typography>{membership.User.Display_Name}</Typography>
                <Typography sx={{ color: '#8c8c8c', wordBreak: 'break-all' }}>
                  <OverflowTooltip
                    value={membership.User.User_Name}
                    variant={'body1'}
                  />
                </Typography>
              </Typography>
            </Grid>
            <Grid xs={3}>
              <Box mr={2}>
                <Typography
                  flex="auto"
                  mr={2}
                  sx={{
                    color:
                      membership.GroupRestrictionLevel ===
                      GroupRestrictionLevel.FullAccess
                        ? '#27aae1'
                        : membership.GroupRestrictionLevel ===
                            GroupRestrictionLevel.ModelLevelAccess
                          ? '#ba68c8'
                          : membership.GroupRestrictionLevel ===
                              GroupRestrictionLevel.ModelInstanceLevelAccess
                            ? '#00bfa5'
                            : 'black',
                  }}
                >
                  {membership.GroupRestrictionLevel ===
                  GroupRestrictionLevel.FullAccess
                    ? 'Full Access'
                    : membership.GroupRestrictionLevel ===
                        GroupRestrictionLevel.ModelLevelAccess
                      ? 'Restricted @ Model'
                      : membership.GroupRestrictionLevel ===
                          GroupRestrictionLevel.ModelInstanceLevelAccess
                        ? 'Restricted @ Model Instance'
                        : membership.GroupRestrictionLevel}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={1} container justifyContent="flex-end">
              {!busy ? (
                <Switch
                  disabled={busy || locked}
                  checked={membership.Role === ModelMembershipRole.Owner}
                  className={UtilClasses.NO_MARGINS}
                  onChange={this.handleChangeRole}
                />
              ) : (
                <Box mr={2} display={'flex'}>
                  <CircularProgress size={20} />
                </Box>
              )}
            </Grid>
            <Grid xs={3} sx={{ display: 'flex', justifyContent: 'right' }}>
              <Link
                to={`/admin/edit-group-permissions?client=${clientId}&model=${modelId}&filter=${filter}&user=${membership.User.id}&membershipId=${membership.id}`}
              >
                <IconButton disabled={busy || locked}>
                  <EditOutlinedIcon sx={{ color: '#c6c6c6' }} />
                </IconButton>
              </Link>
              <IconButton
                disabled={busy || locked}
                onClick={() => this.setState({ deleteModalFlag: true })}
              >
                <Delete sx={{ color: red[700] }} />
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <Box mt={2}>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={Boolean(error)}
                title="Something went wrong"
              >
                <Alert
                  onClose={() => this.setState({ error: undefined })}
                  severity="error"
                >
                  <AlertTitle>Something went wrong</AlertTitle>
                  Check your connection and try again
                </Alert>
              </Snackbar>
            </Box>
          )}
        </CardContent>
        <PopupDialog
          open={this.state.deleteModalFlag}
          close={() => this.setState({ deleteModalFlag: false })}
          title="Remove User ?"
          maxWidth="md"
          primaryButtons={[
            {
              id: 'resetButton',
              label: 'Confirm',
              onClick: this.handleDelete,
            },
          ]}
          isLoading={isDeleting}
        >
          Do you want to remove <b>{membership.User.Display_Name}</b> from the
          model?
          <Typography variant="body2" mt={2}>
            Any group permission restrictions for this user will be cleared and
            will have to be added back if user's model access is restored.
          </Typography>
        </PopupDialog>
      </Card>
    );
  }

  private handleChangeRole = async (event: FormEvent<HTMLInputElement>) => {
    const { membership, profile } = this.props;

    const Role = event.currentTarget.checked
      ? ModelMembershipRole.Owner
      : ModelMembershipRole.Contributor;

    this.setState({ busy: true, error: undefined });

    try {
      await API.update(`/model_memberships/${membership.id}`, {
        Role,
      });

      await (this.context as APICache).load(
        [`/models/${membership.ModelID}/model_memberships`],
        true
      );

      if (profile && profile.User.id === membership.UserID) {
        await loadProfile();
      }
    } catch (error: any) {
      this.setState({ error, busy: false });
      return;
    }

    this.setState(INITIAL_STATE);
  };

  private handleDelete = async () => {
    const { membership, profile } = this.props;

    this.setState({ isDeleting: true, error: undefined });

    try {
      await API.remove(`/model_memberships/${membership.id}`);

      await (this.context as APICache).load(
        [`/models/${membership.ModelID}/model_memberships`],
        true
      );

      if (profile !== undefined && membership.UserID === profile.User.id) {
        await loadProfile();
      }
    } catch (error: any) {
      this.setState({ error, isDeleting: false });
      return;
    }

    // Skip this.setState since the component should be unmounted
  };
}

export default withProfile(TeamMemberCard);
