import React from 'react';
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Container,
  Grid,
} from '@mui/material';
import grey from '@mui/material/colors/grey';
import { useSearchParams } from 'react-router-dom';
import { FolderOpen } from '@mui/icons-material';

import Flex from '../shared/Flex';
import { parseId } from '../../utils/parsing';
import Rule from '../shared/Rule';
import SelectModel from '../shared/SelectModel';

import NonIdealState from '../shared/NonIdealState';

import ModelInstancePermissions from './ModelInstancePermissions';
import SelectClient from './SelectClient';
import TeamMemberList from './TeamMemberList';

const DEFAULT_FILTER = 'client';

const AdminTools = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = () => {
    const clientId = parseId(searchParams.get('client'));
    const modelId = parseId(searchParams.get('model'));
    const filter = searchParams.get('filter') || DEFAULT_FILTER;

    return { clientId, modelId, filter };
  };

  const handleChangeClient = (id: number) => {
    if (id === params().clientId) {
      return;
    }

    searchParams.set('client', id.toString());
    searchParams.delete('model');

    setSearchParams(searchParams);
  };

  const handleChangeModel = (id: number) => {
    if (id === params().modelId) {
      return;
    }

    searchParams.set('model', id.toString());

    setSearchParams(searchParams);
  };

  const handleChangeFilter = (event: React.SyntheticEvent, filter: any) => {
    if (filter === params().filter) {
      return;
    }

    if (filter === DEFAULT_FILTER || filter === undefined) {
      searchParams.delete('filter');
    } else {
      searchParams.set('filter', filter);
    }

    setSearchParams(searchParams);
  };

  const { clientId, modelId, filter } = params();

  return (
    <Container maxWidth="xl" sx={{ paddingBottom: 5 }}>
      <Grid container spacing={2}>
        <Grid item sm={3}>
          <SelectClient
            clientId={clientId}
            onChange={handleChangeClient}
            size="small"
          />
        </Grid>
        <Grid item sm={3}>
          <SelectModel
            filter="admin"
            clientId={clientId}
            modelId={modelId}
            onChange={handleChangeModel}
            size="small"
          />
        </Grid>
      </Grid>
      <Rule />
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Flex alignItems="center" sx={{ my: 2 }}>
            <Box
              flex="auto"
              component={Typography}
              variant="h4"
              color={(t) =>
                modelId === undefined ? t.palette.common.disabled : 'text'
              }
            >
              Team Members
            </Box>
            <ToggleButtonGroup
              exclusive
              size="small"
              id="team-member-filter"
              onChange={handleChangeFilter}
              value={filter}
            >
              <ToggleButton value="client">Client</ToggleButton>
              <ToggleButton value="trinity">Trinity</ToggleButton>
            </ToggleButtonGroup>
          </Flex>
          {clientId !== undefined && modelId !== undefined ? (
            <TeamMemberList
              clientId={clientId}
              modelId={modelId}
              filter={filter}
            />
          ) : (
            <NonIdealState
              description="Please select a client and a model to manage that model's team members"
              title="No Client and Model Selected"
              icon={<FolderOpen />}
            />
          )}
        </Grid>
        <Grid item sm={6}>
          {modelId !== undefined && clientId !== undefined ? (
            <ModelInstancePermissions clientId={clientId} modelId={modelId} />
          ) : (
            <Flex
              p={2}
              flexDirection="column"
              style={{ borderLeft: `1px solid ${grey[400]}` }}
            >
              <Typography variant="h4" color={(t) => t.palette.common.disabled}>
                Model Instance
              </Typography>
              <Typography>Select a Client and Model to start</Typography>
            </Flex>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminTools;
