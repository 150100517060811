import React, { useContext } from 'react';

import { Navigate, Routes, Route } from 'react-router-dom';
import { map } from 'lodash';

import { useSnackbar } from 'notistack';

import { Container } from '@mui/material';

import { Profile } from 'src/types/models';
import { isTrinityUser } from 'src/utils/user';

import EditAssumptionsDetail from '../ScenarioManager/EditAssumptionsDetail';

import OutputsPanel from '../ScenarioManager/EditAssumptionsDetail/OutputsPanel';

import EditGroupPermissions from '../Admin/EditGroupPermissions';

import RouteParams from './RouteParams';
import ProtectedRoute from './ProtectedRoute';
import Unauthorized from './Unauthorized';
import { ProfileContext } from './AuthController';

const RoutesComponent = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleInvalidRoute = (path: string, label: string) => {
    enqueueSnackbar(
      `Your company admin for CloudCast has not given you permission to
                access the ${label} page. Please reach out to them
                if you think you should have access.`,
      { variant: 'error' }
    );
  };

  const profile = useContext(ProfileContext);
  if (profile === undefined) {
    throw Error('Profile is required to render NavigationBar');
  }

  const showForAdmin = isTrinityUser((profile as Profile).User);

  return (
    <>
      <Routes>
        {map(RouteParams().routes, (route) => {
          if (!route.protected) {
            return (
              <Route
                key={route.pathName}
                path={`${route.pathName}`}
                element={route.component}
              />
            );
          }
          return (
            <ProtectedRoute
              path={`${route.pathName}`}
              label={route.label}
              key={route.pathName}
              callback={handleInvalidRoute}
            />
          );
        })}
        <Route
          path="/scenario-manager/assumptions"
          element={<EditAssumptionsDetail />}
        />
        <Route
          path="/scenario-manager/assumptions/outputs"
          element={
            <Container maxWidth={false}>
              <OutputsPanel inContainer />
            </Container>
          }
        />
        {showForAdmin && (
          <Route
            path="/admin/edit-group-permissions"
            element={<EditGroupPermissions />}
          />
        )}
        <Route path="/unauthorized" element={<Unauthorized />} />

        <Route
          path="*"
          element={
            <Navigate to={RouteParams().scenarioManagerRoute.pathName} />
          }
        />
      </Routes>
    </>
  );
};

export default RoutesComponent;
