import { Box, Card, Grid, Typography } from '@mui/material';
import React, { PureComponent } from 'react';
import { filter as filterList, isArray, map } from 'lodash';

import { ModelMembership } from '../../types/models';
import { filterUser } from '../../utils/user';

import Loader, { LoaderInfo } from '../shared/Loader';

import TeamMemberCard from './TeamMemberCard';
import TeamMemberInvite from './TeamMemberInvite';

interface Props {
  clientId: number;
  modelId: number;
  filter: string;
}

class TeamMemberList extends PureComponent<Props> {
  public render() {
    const { modelId } = this.props;

    const needs = {
      memberships: `/models/${modelId}/model_memberships`,
    };

    return (
      <>
        <Loader
          needs={needs}
          handleErrors={true}
          render={this.renderContents}
          force={true}
        />
      </>
    );
  }

  private renderContents = ({
    data,
  }: LoaderInfo<{ memberships: ModelMembership }>) => {
    const { clientId, modelId, filter } = this.props;
    const { memberships } = data;

    if (isArray(memberships)) {
      return (
        <>
          <TeamMemberInvite
            clientId={clientId}
            modelId={modelId}
            filter={filter}
            memberships={memberships}
          />
          &nbsp;
          {this.renderList(memberships)}
        </>
      );
    } else {
      return null;
    }
  };

  private renderList = (memberships: ModelMembership[]) => {
    const { clientId, modelId, filter } = this.props;

    return (
      <Box>
        {filterList(memberships, (membership) =>
          filterUser(membership.User, filter)
        ).length > 0 && (
          <Card
            sx={{ px: 1.5, py: 1, backgroundColor: 'rgba(84, 110, 178, 0.2)' }}
          >
            <Grid container alignItems="center">
              <Grid item xs={5}>
                <Typography flex="auto" mr={2}>
                  <b>User</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography flex="auto" mr={2}>
                  <b>Restriction Level</b>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography flex="auto" mr={2}>
                  <b>Model Owner</b>
                </Typography>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </Card>
        )}
        {map(
          filterList(memberships, (membership) =>
            filterUser(membership.User, filter)
          ).sort(({ User: a }, { User: b }) =>
            a.Display_Name > b.Display_Name
              ? 1
              : b.Display_Name > a.Display_Name
                ? -1
                : 0
          ),
          (membership) => (
            <Box key={membership.id} mt={2}>
              <TeamMemberCard
                membership={membership}
                clientId={clientId}
                modelId={modelId}
                filter={filter}
              />
            </Box>
          )
        )}
      </Box>
    );
  };
}

export default TeamMemberList;
