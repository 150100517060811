import React, { FunctionComponent } from 'react';
import { Box, ClickAwayListener, Tooltip } from '@mui/material';

import InfoIcon from '../../assets/images/info_icon.svg';

interface IFileInputProps {
  tooltipContent: string | '';
  placement?: string;
}

const InformationIcon: FunctionComponent<IFileInputProps> = ({
  tooltipContent,
  placement = 'bottom-start',
}) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const toggleTooltip = () => {
    setOpen(!open);
  };
  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            open={open}
            componentsProps={{
              tooltip: {
                sx: {
                  background: '#666666',
                  maxWidth: 500,
                  maxHeight: 350,
                  overflowY: 'auto',
                },
              },
            }}
            title={<div dangerouslySetInnerHTML={{ __html: tooltipContent }} />}
            placement={
              placement as
                | 'bottom-start'
                | 'bottom'
                | 'left'
                | 'right'
                | 'top'
                | 'bottom-end'
                | 'left-end'
                | 'left-start'
                | 'right-end'
                | 'right-start'
                | 'top-end'
                | 'top-start'
                | undefined
            }
          >
            <Box
              className="info-icon-img"
              onClick={toggleTooltip}
              sx={{ display: 'flex' }}
              component="img"
              src={InfoIcon}
              pl={1}
            ></Box>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default InformationIcon;
